.SalesPriorityInteractionCard {
    flex-direction: column;
    display: flex;

    .priority-date-status-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;      
    }

    .priority-date-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .priority-container {
        display: block;
        border-radius: 50px;
        background-color: var(--color_red_2);
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 5px;
        overflow: hidden;
        color: var(--color_red_5);
        font-size: var(--fontSize_small);
        font-weight: var(--fontWeight_bold);        
    }

    .priority-label {
        margin: auto;
    }

    .date-label {
        margin: auto;
        color: var(--color_black_1);
        font-weight: var(--fontWeight_bold);       
    }

    .status-container {
        margin-top: auto; 
        margin-bottom: auto;
    }

    .priority-name-label {
        color: var(--color_black_1);
        font-weight: var(--fontWeight_bold);
        margin: auto;
    }

    .priority-description-label {
        color: var(--color_gray_6);
        font-weight: var(--fontWeight_semibold);
        margin: auto;
    }

    .priority-user-and-status {
        flex-direction: row;
        padding-bottom: 4;
        align-items: center;
        display: flex;
        justify-content: space-between;
        
        .priority-interaction-user {
            label {
                color: var(--color_black_1);
                font-size: var(--fontSize_base);
                font-weight: var(--fontWeight_bold);
                margin-bottom: 0;                
            }
        }
    }

    .priority-user-roles {
        padding-bottom: 5px;

        label {
            color: var(--color_gray_6);
            font-size: var(--fontSize_small);
            font-weight: var(--fontWeight_semibold);
            margin: 0;            
        }
    }
}
