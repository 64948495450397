@import "buttons";
@import "inputs";
@import "scrollbar";
@import "modifiers";
@import "spinner";
@import "tooltips";

// default font sizes
:root {
    --fontSize_base: 16px;
    --fontSize_h1: 30px;
    --fontSize_h2: 26px;
    --fontSize_h3: 22px;
    --fontSize_h4: 18px;
    --fontSize_h5: 14px;
    --fontSize_h6: 10px;
    --fontSize_p: 14px;
    --fontSize_small: 12px;
    --fontSize_x_small: 10px;

    // Font opacity
    --font_opacity: 0.6;
    --font_opacity_hightlighted: 0.6;

    // font weight
    --fontWeight_normal: 300;
    --fontWeight_regular: 400;
    --fontWeight_semibold: 500;
    --fontWeight_bold: 600;
    --fontWeight_bolder: 900;

    //general line-height
    --lineHeight_normal: 18px;
    --lineHeight_small: 16px;

    --backGroundColor_default: var(--color_white_1);

    //general elements font color
    --fontColor_default: var(--color_black_1);
    --fontColor_gridHeader: var(--color_gray_6);

    --fontColor_navbar: var(--color_primary_1);
    --fontColor_navbar_highlighted: var(--color_white_1);
    --fontColor_h1: var(--color_black_1);

    --backGroundColor_icon_button: var(--color_gray_3);

    // sidebar
    --width_sidebar: 63px;
    --width_sidebar_open: 250px;
    --fontSize_sidebar_text: 15px;
    --fontSize_sidebar_icon: 25px;
    --fontSize_sidebar_toggle_icon: 18px;
    --fontSize_sidebar_spring_icon: 35px;
    --fontColor_navbar_active: var(--color_white_1);

    // header
    --height_header: 76px;

    //teammanagement
    --fontSize-tm-large: 14px;
    --fontSize-tm-medium: 12px;
    --fontSize-tm-small: 10px;
    --fontSize-tm-x-small: 8px;
}

@import "modals";